import React from 'react';

const TeamMember = ({image, membername, sport}) => {
  return ( 
    <div className="mb-7">
      <div className="flex justify-center mb-5">{image}</div>
      <div className="text-center text-2xl font-bold">{membername}</div>
      <div className="text-center px-4">{sport}</div>
    </div>
   );
}
 
export default TeamMember;